import {Injectable, signal, TemplateRef} from '@angular/core';

export type ToastType = {
  textOrTpl: string | TemplateRef<any>;
  classname?: string;
  delay?: number;
};

@Injectable({ providedIn: 'root' })
export class ToastService {
  readonly toasts = signal<(ToastType)[]>([]);

  show(textOrTpl: string | TemplateRef<any>, options: any = {}) {
    this.toasts.set([...this.toasts(), { textOrTpl: textOrTpl, ...options }]);
  }

  remove(toast: ToastType) {
    this.toasts.set(this.toasts().filter(t => t !== toast));
  }

  clear() {
    this.toasts.set([]);
  }
}
